import { AppDispatch, AppThunk } from 'store';
import { v4 as uuidv4 } from 'uuid';

import { ChatDialogSaveStatus } from 'types/enums/chat/ChatDialogSaveStatus';
import { ChatDialogTab } from 'types/enums/chat/ChatDialogTab';
import { ChatMessageFormat } from 'types/enums/chat/ChatMessageFormat';
import { ChatMessageStatus } from 'types/enums/chat/ChatMessageStatus';
import { ChatMessageType } from 'types/enums/chat/ChatMessageType';
import { ChatDialog } from 'types/interfaces/chat/ChatDialog';
import { ChatDialogFilters } from 'types/interfaces/chat/ChatDialogFilters';
import { ChatGift } from 'types/interfaces/chat/ChatGift';
import { ChatMessage } from 'types/interfaces/chat/ChatMessage';
import { ChatSticker } from 'types/interfaces/chat/ChatSticker';
import { Photo } from 'types/interfaces/Photo';

import { DialogsApi } from 'api/DialogsApi';
import { setCommonToastError } from 'store/common/commonSlice';

import {
  addDialogs,
  addMessage,
  addMessages,
  hideDialog,
  removeDialog,
  setDialogs,
  setDialogsCounters,
  setDialogsFilters,
  setDialogsLoading,
  setGifts,
  setGiftsLoading,
  setMessages,
  setMessagesLoading,
  setStickerPacks,
  setStickerPacksLoading,
  unHideDialog,
  updateDialogReadStatus,
  updateDialogSaveStatus,
  updateDialogWithNewMessage,
  updateMessageStatus,
} from './messengerSlice';

const MIN_CREDITS_SPEND_FOR_EROTIC = 5;

export const fetchDialogsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    dispatch(setDialogsLoading(true));

    const dialogsResponse =
      await DialogsApi.fetchDialogsWithFilters(dialogsFilters);

    dispatch(setDialogsCounters(dialogsResponse.tab_counters));
    dispatch(setDialogs(dialogsResponse));

    dispatch(setDialogsLoading(false));
  };

export const refetchDialogsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    const dialogsResponse =
      await DialogsApi.fetchDialogsWithFilters(dialogsFilters);

    dispatch(setDialogs(dialogsResponse));
  };

export const fetchMoreDialogsThunk =
  (): AppThunk => async (dispatch: AppDispatch, getState) => {
    const nextUrl = getState().messenger.dialogs.next;

    const dialogsResponse = await DialogsApi.fetchNextDialogs(nextUrl);

    dispatch(addDialogs(dialogsResponse));
  };

export const refetchDialogsWithFiltersThunk =
  (payload: Partial<ChatDialogFilters>): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    const updatedFilters = { ...dialogsFilters, ...payload };

    dispatch(setDialogsFilters(updatedFilters));

    dispatch(setDialogsLoading(true));

    const dialogsResponse =
      await DialogsApi.fetchDialogsWithFilters(updatedFilters);

    dispatch(setDialogs(dialogsResponse));

    dispatch(setDialogsLoading(false));
  };

export const updateDialogHideStatusThunk =
  (payload: {
    contactId: string;
    userId: string;
    shouldHide: boolean;
    cb?: () => void;
  }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { dialogsFilters } = getState().messenger;

    const data = await DialogsApi.updateDialogHideStatus(
      payload.contactId,
      payload.shouldHide
    );

    if (data.status && payload.shouldHide) {
      dispatch(
        hideDialog({
          contactId: payload.contactId,
          userId: payload.userId,
        })
      );

      if (dialogsFilters.tab !== ChatDialogTab.Hidden)
        payload.cb && payload.cb();
    }

    if (data.status && !payload.shouldHide)
      dispatch(
        unHideDialog({
          contactId: payload.contactId,
          userId: payload.userId,
        })
      );
  };

export const updateDialogSaveStatusThunk =
  (payload: {
    isSaved: ChatDialogSaveStatus;
    contactId: string;
    userId: string;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    await DialogsApi.updateDialogSaveStatus({
      isSaved: payload.isSaved,
      contactId: payload.contactId,
    });

    dispatch(
      updateDialogSaveStatus({
        isSaved: payload.isSaved,
        contactId: payload.contactId,
        userId: payload.userId,
      })
    );
  };

export const fetchMessagesThunk =
  (payload: { contactId: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setMessagesLoading(true));

    const messagesResponse = await DialogsApi.fetchMessages(payload.contactId);

    dispatch(setMessagesLoading(false));
    dispatch(
      setMessages({
        user: messagesResponse.user,
        contact: messagesResponse.contact,
        dialog: messagesResponse.dialog,
        messages: messagesResponse.messages,
        next: messagesResponse.next,
        isLiked: messagesResponse.is_liked,
        isBlocked: messagesResponse.blocked_me,
        isEnabledEroticMedia:
          messagesResponse.contact?.credits?.spend === '20+' ||
          (messagesResponse.contact?.credits?.spend || 0) >=
            MIN_CREDITS_SPEND_FOR_EROTIC,
        limits: messagesResponse.limits,
      })
    );
    dispatch(setMessagesLoading(false));
  };

export const fetchMoreMessagesThunk =
  (payload: { contactId: string; userId: string }): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const { contactId, userId } = payload;
    const { messenger } = getState();

    const nextUrl = messenger.messages[`${contactId}-${userId}`].next;
    const isMessagesLoading = messenger.messagesLoading;

    if (!nextUrl || isMessagesLoading) return;

    dispatch(setMessagesLoading(true));

    const messagesResponse = await DialogsApi.fetchMoreMessages(nextUrl);

    dispatch(setMessagesLoading(false));
    dispatch(
      addMessages({
        contactId,
        userId,
        messages: messagesResponse.messages,
        next: messagesResponse.next,
      })
    );
  };

export const readIncomingMessagesThunk =
  (payload: {
    messageId: number;
    contactId: string;
    userId: string;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    await DialogsApi.readIncomingMessages({
      contactId: payload.contactId,
      messageId: payload.messageId,
    });

    dispatch(
      updateDialogReadStatus({
        contactId: payload.contactId,
        userId: payload.userId,
        isIncoming: true,
      })
    );
  };

export const sendTextMessageThunk =
  (payload: { contactId: string; userId: string; body: string }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          body: payload.body,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Text,
          id: 0,
          request_id: null,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: 1,
        },
        contactId: payload.contactId,
        userId: payload.userId,
      })
    );

    // TODO add this logic for all handlers
    try {
      await DialogsApi.sendTextMessage({
        contactId: payload.contactId,
        body: payload.body,
        frontMessageId,
      });
    } catch (error: any) {
      dispatch(
        updateMessageStatus({
          message: {
            front_message_id: frontMessageId,
            status: ChatMessageStatus.Failed,
          },
          contactId: payload.contactId,
          userId: payload.userId,
        })
      );

      dispatch(
        setCommonToastError(
          error?.response?.data?.body || "Oops, the message wasn't sent"
        )
      );
    }
  };

export const sendGiftMessageThunk =
  (payload: {
    contactId: string;
    userId: string;
    gift: ChatGift;
    message: string;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          body: payload.message || null,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Gift,
          id: 0,
          request_id: null,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: 1,
          gift: payload.gift,
        },
        contactId: payload.contactId,
        userId: payload.userId,
      })
    );

    DialogsApi.sendGiftMessage({
      contactId: payload.contactId,
      giftId: payload.gift.id,
      body: payload.message,
      frontMessageId,
    });
  };

export const sendStickerMessageThunk =
  (payload: {
    contactId: string;
    userId: string;
    sticker: ChatSticker;
  }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          body: null,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Sticker,
          id: 0,
          request_id: null,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: 1,
          sticker: payload.sticker,
        },
        contactId: payload.contactId,
        userId: payload.userId,
      })
    );

    DialogsApi.sendStickerMessage({
      contactId: payload.contactId,
      stickerId: payload.sticker.id,
      frontMessageId,
    });
  };

export const sendPhotoMessageThunk =
  (payload: { contactId: string; userId: string; photo: Photo }): AppThunk =>
  async (dispatch: AppDispatch) => {
    const frontMessageId = uuidv4();

    dispatch(
      addMessage({
        message: {
          body: null,
          front_message_id: frontMessageId,
          format: ChatMessageFormat.Photo,
          id: 0,
          request_id: null,
          is_incoming: false,
          sent_at: new Date().toISOString(),
          status: ChatMessageStatus.Loading,
          type: 1,
          photo: payload.photo,
        },
        contactId: payload.contactId,
        userId: payload.userId,
      })
    );

    DialogsApi.sendPhotoMessage({
      contactId: payload.contactId,
      photoId: payload.photo.id,
      format: ChatMessageFormat.Photo,
      frontMessageId,
    });
  };

export const addNewMessageFromSocketThunk =
  (payload: {
    message: ChatMessage;
    contactId: string;
    userId: string;
  }): AppThunk =>
  (dispatch: AppDispatch, getState) => {
    const { dialogs, dialogsFilters } = getState().messenger;

    dispatch(addMessage(payload));

    const hasDialogWith =
      (contactId: string, userId: string) => (dialog: ChatDialog) =>
        dialog.contact?.ulid_id === contactId &&
        dialog.user?.ulid_id === userId;

    if (
      dialogsFilters.tab === ChatDialogTab.Featured &&
      payload.message?.type === ChatMessageType.General
    ) {
      dispatch(
        removeDialog({ contactId: payload.contactId, userId: payload.userId })
      );

      return;
    }

    if (dialogs.data.some(hasDialogWith(payload.contactId, payload.userId))) {
      dispatch(updateDialogWithNewMessage(payload));
    } else {
      dispatch(refetchDialogsThunk());
    }
  };

export const fetchGiftsThunk =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setGiftsLoading(true));

    const { data: giftsResponse } = await DialogsApi.fetchGifts();

    dispatch(setGifts(giftsResponse));
    dispatch(setGiftsLoading(false));
  };

export const fetchStickerPacksThunk =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setStickerPacksLoading(true));

    const { data: stickerPacksResponse } = await DialogsApi.fetchStickerPacks();

    dispatch(setStickerPacks(stickerPacksResponse));
    dispatch(setStickerPacksLoading(false));
  };
