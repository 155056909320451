import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MediaType } from 'types/enums/MediaType';
import {
  ExtendedUserDetails,
  UserEditOptions,
} from 'types/interfaces/user/UserDetails';
import { MediaAccess, UserProfile } from 'types/interfaces/user/UserProfile';

interface ProfileState {
  isLoading: boolean;
  profile: UserProfile | null;
  profileDetails: ExtendedUserDetails | null;
  profileOptions: UserEditOptions | null;
  profileMediaAccess: Record<string, MediaAccess>;
}

const initialState: ProfileState = {
  isLoading: true,
  profile: null,
  profileDetails: null,
  profileOptions: null,
  profileMediaAccess: {},
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<UserProfile | null>) {
      state.profile = action.payload;
    },
    setProfileLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateProfile(state, action: PayloadAction<Partial<UserProfile>>) {
      if (state.profile)
        state.profile = { ...state.profile, ...action.payload };
    },

    resetState() {
      return initialState;
    },
    setProfileDetails(state, action: PayloadAction<ExtendedUserDetails>) {
      state.profileDetails = action.payload;
    },
    updateProfileDetails(
      state,
      action: PayloadAction<Partial<ExtendedUserDetails>>
    ) {
      if (state.profileDetails) {
        state.profileDetails = { ...state.profileDetails, ...action.payload };
      }
    },
    setProfileOptions(state, action: PayloadAction<UserEditOptions>) {
      state.profileOptions = action.payload;
    },
    setProfileMediaAccess(
      state,
      action: PayloadAction<{
        userId: string;
        contactId: string;
        access: {
          accesses: Record<MediaType, string[]>;
          mass_accesses: MediaType[];
        };
      }>
    ) {
      const { userId, contactId, access } = action.payload;

      state.profileMediaAccess[`${userId}-${contactId}`] = access;
    },
  },
});

export const {
  setProfile,
  setProfileLoading,
  updateProfile,
  resetState,
  setProfileDetails,
  updateProfileDetails,
  setProfileOptions,
  setProfileMediaAccess,
} = profileSlice.actions;

export const profile = profileSlice.reducer;
