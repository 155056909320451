import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from 'store/auth/selectors';

import { useSystemSearchQueries } from './useSystemSearchQueries';

export const useUserProfileUlid = () => {
  const currentUser = useSelector(getUser);

  const { xUlidQuery } = useSystemSearchQueries();

  const userProfileUlid = useMemo(
    () => xUlidQuery.value || currentUser?.ulid_id || '',
    [currentUser?.ulid_id, xUlidQuery.value]
  );

  return { userProfileUlid };
};
