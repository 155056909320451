import cookie from 'js-cookie';
import { v4 as uuidv4, validate } from 'uuid';

import { MirrorService } from 'services/MirrorService';

import { TrackingApi } from 'api/TrackingApi';
import { useSystemSearchQueries } from 'hooks/useSystemSearchQueries';

export const INITIAL_ID_COOKIE_NAME = 'initial_id';
export const INITIAL_ID_EXPIRE = 36500;

export const useInitialId = () => {
  const traceId = uuidv4();

  const { initialIdQuery } = useSystemSearchQueries();

  const generateInitialId = () => uuidv4();

  const getInitialIdFromAPI = async (): Promise<any> => {
    try {
      const { data } = await TrackingApi.fetchInitialId(traceId);

      return data.initial_id;
    } catch (err: any) {
      console.error(err);
    }
    return generateInitialId();
  };

  const getInitialIdFromCookie = () => cookie.get(INITIAL_ID_COOKIE_NAME) || '';

  const setInitialIdToCookie = (initialId: string) => {
    const currentDomain = MirrorService.domainWithoutSubdomain;

    cookie.set(INITIAL_ID_COOKIE_NAME, initialId, {
      domain: `.${currentDomain}`,
      expires: INITIAL_ID_EXPIRE,
      sameSite: 'None',
      secure: true,
    });
  };

  const getInitialId = (): Promise<string> => {
    return new Promise((resolve) => {
      const initialIdFromCookie = getInitialIdFromCookie();
      const initialIdFromQuery = initialIdQuery.value;
      const isInitialIdFromCookieValid =
        initialIdFromCookie && validate(initialIdFromCookie);
      const isInitialIdFromQueryValid =
        initialIdFromQuery && validate(initialIdFromQuery);

      if (
        (initialIdFromCookie && !initialIdFromQuery) ||
        (initialIdFromQuery === initialIdFromCookie &&
          isInitialIdFromCookieValid)
      ) {
        resolve(initialIdFromCookie);
        return;
      }

      if (initialIdFromQuery && isInitialIdFromQueryValid) {
        resolve(initialIdFromQuery);
        return;
      }

      getInitialIdFromAPI().then(resolve);
    });
  };

  return {
    getInitialId,
    getInitialIdFromCookie,
    setInitialIdToCookie,
  };
};
