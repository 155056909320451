import { AppThunk } from 'store';

import { AuthApi } from 'api/AuthApi';
import { SettingsApi } from 'api/SettingsApi';
import { setUser } from 'store/auth/authSlice';

import {
  setInitState,
  setIsEnabledNotificationsSound,
  setLoading,
} from './commonSlice';

export const init = (): AppThunk => async (dispatch, getState) => {
  const state = getState();

  dispatch(setLoading(true));

  const initData = await AuthApi.init({
    from: state.common.prevPagePathname,
  });

  dispatch(
    setInitState({
      loading: false,
      unreadCount: initData?.unread_counters || {},
      currentLang: initData.site_language?.value,
      supportedLangs: Object.values(initData.site_language.options),
      featureFlags: {
        isEnabledNotificationsSound: initData.sound_notification,
        isEnabledIcebreakersChains: initData.is_enabled_icebreakers_chains,
      },
    })
  );

  dispatch(setUser(initData.user));
};

export const updateNotificationsSoundThunk =
  (payload: boolean): AppThunk =>
  async (dispatch) => {
    await SettingsApi.updateNotificationsSoundFlag(payload);

    dispatch(setIsEnabledNotificationsSound(payload));
  };
