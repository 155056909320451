import React, { useCallback, useEffect, useMemo } from 'react';

import { MirrorService } from 'services/MirrorService';

import { setXUlidId } from 'api/httpClient';
import { getProfilesListInfo } from 'helpers/profiles';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right.svg';

import { Popup } from 'components/shared/Popup';
import { UserAvatar } from 'components/shared/UserAvatar';

import css from './profileSelectPopup.module.sass';

interface Props {
  params: {
    cb: () => void;
  };
  onClose: () => void;
}

export const ProfileSelectPopup: React.FC<Props> = ({ params, onClose }) => {
  const profiles = useMemo(() => getProfilesListInfo(), []);

  const handleProfileClick = useCallback(
    (id: string) => {
      setXUlidId(id);

      params?.cb && params.cb();
      onClose();
    },
    [onClose, params]
  );

  useEffect(() => {
    if (!profiles) {
      onClose();

      params?.cb && params.cb();
    }
  }, [onClose, params, profiles]);

  if (!profiles || !profiles.length) return null;

  return (
    <Popup
      onClose={onClose}
      popupClassName={css.root}
      bodyClassName={css.content}
    >
      <h3 className={css.title}>Choose your profile</h3>
      {profiles.map((profile) => (
        <div
          className={css.profile}
          key={profile.external_id}
          onClick={() => handleProfileClick(profile.external_id)}
        >
          <UserAvatar
            url={MirrorService.resolveImagePath(profile.image_url)}
            size={48}
          />
          <p className={css.name}>{profile.name}</p>
          <ArrowIcon className={css.arrow} />
        </div>
      ))}
    </Popup>
  );
};
