import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChatDialogHideStatus } from 'types/enums/chat/ChatDialogHideStatus';
import { ChatDialogSaveStatus } from 'types/enums/chat/ChatDialogSaveStatus';
import { ChatDialogTab } from 'types/enums/chat/ChatDialogTab';
import { ChatMessageStatus } from 'types/enums/chat/ChatMessageStatus';
import { ChatDialog } from 'types/interfaces/chat/ChatDialog';
import { ChatDialogFilters } from 'types/interfaces/chat/ChatDialogFilters';
import { ChatDialogTabCounters } from 'types/interfaces/chat/ChatDialogTabCounters';
import { ChatGift } from 'types/interfaces/chat/ChatGift';
import { ChatLimits } from 'types/interfaces/chat/ChatLimits';
import { ChatMessage } from 'types/interfaces/chat/ChatMessage';
import { ChatStickerPack } from 'types/interfaces/chat/ChatStickerPack';
import { UserContact } from 'types/interfaces/user/UserContact';

import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';

const DIALOGS_FILTERS_STORAGE_KEY = 'dialogs_filters';
const DEFAULT_DIALOGS_FILTERS = {
  search: '',
  tab: ChatDialogTab.All,
  is_online: false,
};

const initDialogsFilters = () => {
  try {
    const dialogsFiltersLS = getLocalStorageItem(DIALOGS_FILTERS_STORAGE_KEY);

    if (!dialogsFiltersLS) return DEFAULT_DIALOGS_FILTERS;

    return JSON.parse(dialogsFiltersLS);
  } catch (error) {
    return DEFAULT_DIALOGS_FILTERS;
  }
};

interface Dialogs {
  data: ChatDialog[];
  next: string | null;
}

interface TypingContactInfo {
  contactId: string;
  userId: string;
}

type MessengerState = {
  dialogs: Dialogs;
  dialogsFilters: ChatDialogFilters;
  dialogsLoading: boolean;
  dialogsCounters: Record<string, ChatDialogTabCounters>;

  messages: Record<
    string,
    {
      next: string | null;
      isLiked: boolean;
      isEnabledEroticMedia: boolean;
      isBlocked: boolean;
      user: UserContact;
      contact: UserContact;
      dialog: ChatDialog;
      messages: ChatMessage[];
      limits: ChatLimits;
    }
  >;
  messagesLoading: boolean;

  gifts: ChatGift[];
  giftsLoading: boolean;

  stickerPacks: ChatStickerPack[];
  stickerPacksLoading: boolean;

  typingContacts: TypingContactInfo[];
};

const initialState: MessengerState = {
  dialogs: {
    data: [],
    next: null,
  },
  dialogsLoading: true,
  dialogsFilters: initDialogsFilters(),
  dialogsCounters: {},

  messages: {},
  messagesLoading: true,

  gifts: [],
  giftsLoading: true,

  stickerPacks: [],
  stickerPacksLoading: true,

  typingContacts: [],
};

const updateDialogWithNewMessageMapper =
  (newMessage: ChatMessage, contactId: string, userId: string) =>
  (dialog: ChatDialog) => {
    const isDialogWithProperContact =
      dialog.contact?.ulid_id === contactId && dialog.user?.ulid_id === userId;

    if (!isDialogWithProperContact) {
      return dialog;
    }

    return {
      ...dialog,
      unread_count: newMessage.is_incoming ? dialog.unread_count + 1 : 0,
      contact_unread_count: newMessage.is_incoming
        ? 0
        : dialog.unread_count + 1,
      last_message_body: newMessage.body || null,
      last_message_format: newMessage.format,
      last_message_sent_at: newMessage.sent_at,
      last_message_type: newMessage.type,
    };
  };

const setMessageRead = () => (message: ChatMessage) => {
  if (message.is_incoming || message.status === ChatMessageStatus.Failed) {
    return message;
  }

  return {
    ...message,
    status: ChatMessageStatus.Read,
  };
};

const messengerSlice = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    updateChatContactLimits(
      state,
      action: PayloadAction<{
        contactId: string;
        userId: string;
        limits: Partial<ChatLimits>;
      }>
    ) {
      const { contactId, userId, limits } = action.payload;

      if (state.messages[`${contactId}-${userId}`]) {
        state.messages[`${contactId}-${userId}`].limits = {
          ...state.messages[`${contactId}-${userId}`].limits,
          ...limits,
        };
      }
    },

    // ? ***************** DIALOGS ACTIONS START *****************
    setDialogs(state, action: PayloadAction<Dialogs>) {
      state.dialogs = action.payload;
    },

    setDialogsLoading(state, action: PayloadAction<boolean>) {
      state.dialogsLoading = action.payload;
    },

    addDialog(state, action: PayloadAction<ChatDialog>) {
      state.dialogs.data = [action.payload].concat(state.dialogs.data);
    },

    addDialogs(state, action: PayloadAction<Dialogs>) {
      state.dialogs = {
        data: state.dialogs.data.concat(action.payload.data),
        next: action.payload.next,
      };
    },

    removeDialog(
      state,
      action: PayloadAction<{ contactId: string; userId: string }>
    ) {
      state.dialogs.data = state.dialogs.data.filter(
        (dialogItem) =>
          dialogItem.contact.ulid_id !== action.payload.contactId ||
          dialogItem.user?.ulid_id !== action.payload.userId
      );
    },

    setDialogsCounters(
      state,
      action: PayloadAction<Record<string, ChatDialogTabCounters>>
    ) {
      state.dialogsCounters = { ...state.dialogsCounters, ...action.payload };
    },

    setDialogsFilters(state, action: PayloadAction<ChatDialogFilters>) {
      state.dialogsFilters = action.payload;

      setLocalStorageItem(
        DIALOGS_FILTERS_STORAGE_KEY,
        JSON.stringify(action.payload)
      );
    },

    updateDialogWithNewMessage(
      state,
      action: PayloadAction<{
        message: ChatMessage;
        contactId: string;
        userId: string;
      }>
    ) {
      const { message: newMessage, contactId, userId } = action.payload;
      const isExistInDialogs = Boolean(
        state.dialogs.data.find(
          (dialog) =>
            dialog.contact?.ulid_id === contactId &&
            dialog.user?.ulid_id === userId
        )
      );

      if (isExistInDialogs) {
        state.dialogs.data = state.dialogs.data.map(
          updateDialogWithNewMessageMapper(newMessage, contactId, userId)
        );
      }
    },

    updateDialogReadStatus(
      state,
      action: PayloadAction<{
        contactId: string;
        userId: string;
        isIncoming: boolean;
      }>
    ) {
      const { contactId, userId, isIncoming } = action.payload;

      const isExistInDialogs = Boolean(
        state.dialogs.data.find(
          (dialog) =>
            dialog.contact?.ulid_id === contactId &&
            dialog.user?.ulid_id === userId
        )
      );

      if (isExistInDialogs) {
        state.dialogs.data = state.dialogs.data.map((dialog) => {
          if (
            dialog.contact?.ulid_id === contactId &&
            dialog.user?.ulid_id === userId
          ) {
            return isIncoming
              ? { ...dialog, unread_count: 0 }
              : { ...dialog, contact_unread_count: 0 };
          }
          return dialog;
        });
      }
    },

    hideDialog(
      state,
      action: PayloadAction<{
        contactId: string;
        userId: string;
      }>
    ) {
      const { contactId, userId } = action.payload;
      const dialogFilters = state.dialogsFilters;
      const { dialog, contact, user } =
        state.messages[`${contactId}-${userId}`];

      if (dialogFilters.tab === ChatDialogTab.Hidden) {
        state.dialogs.data = [
          { ...dialog, contact, user },
          ...state.dialogs.data,
        ];
      }

      if (dialogFilters.tab !== ChatDialogTab.Hidden) {
        state.dialogs.data = state.dialogs.data.filter(
          (dialogItem) =>
            dialogItem.contact?.ulid_id !== contactId ||
            dialogItem.user?.ulid_id !== userId
        );
      }

      state.messages[`${contactId}-${userId}`].dialog.is_hide =
        ChatDialogHideStatus.Hidden;
    },

    unHideDialog(
      state,
      action: PayloadAction<{
        contactId: string;
        userId: string;
      }>
    ) {
      const { contactId, userId } = action.payload;
      const dialogFilters = state.dialogsFilters;

      if (dialogFilters.tab === ChatDialogTab.Hidden) {
        state.dialogs.data = state.dialogs.data.filter(
          (dialogItem) =>
            dialogItem.contact?.ulid_id !== contactId ||
            dialogItem.user?.ulid_id !== userId
        );
      }

      state.messages[`${contactId}-${userId}`].dialog.is_hide =
        ChatDialogHideStatus.Shown;
    },

    updateDialogSaveStatus(
      state,
      action: PayloadAction<{
        contactId: string;
        userId: string;
        isSaved: ChatDialogSaveStatus;
      }>
    ) {
      const { contactId, userId, isSaved } = action.payload;
      const { dialog, contact, user } =
        state.messages[`${contactId}-${userId}`];
      const { dialogsFilters } = state;

      state.messages[`${contactId}-${userId}`] = {
        ...state.messages[`${contactId}-${userId}`],
        dialog: { ...dialog, is_saved: isSaved },
      };

      if (
        dialogsFilters.tab === ChatDialogTab.Saved &&
        isSaved === ChatDialogSaveStatus.Saved
      ) {
        state.dialogs.data = [
          ...state.dialogs.data,
          { ...dialog, contact, user },
        ];
      }

      if (
        dialogsFilters.tab === ChatDialogTab.Saved &&
        isSaved === ChatDialogSaveStatus.NotSaved
      ) {
        state.dialogs.data = state.dialogs.data.filter(
          (dialogItem) =>
            dialogItem.contact.ulid_id !== contactId ||
            dialogItem.user?.ulid_id !== userId
        );
      }
    },

    updateDialogLastMessageIncomingStatus(
      state,
      action: PayloadAction<{
        contactId: string;
        isIncoming: number;
      }>
    ) {
      const { contactId, isIncoming } = action.payload;

      state.dialogs.data = state.dialogs.data.map((dialog) => {
        if (dialog.contact?.ulid_id === contactId) {
          return { ...dialog, is_last_message_incoming: isIncoming };
        }
        return dialog;
      });
    },

    markDialogAsNotNew(
      state,
      action: PayloadAction<{
        contactId: string;
        userId: string;
      }>
    ) {
      const { contactId, userId } = action.payload;

      const isExistInDialogs = Boolean(
        state.dialogs.data.find(
          (dialog) =>
            dialog.contact?.ulid_id === contactId &&
            dialog.user?.ulid_id === userId
        )
      );

      if (isExistInDialogs) {
        state.dialogs.data = state.dialogs.data.map((dialog) => {
          if (
            dialog.contact?.ulid_id === contactId &&
            dialog.user?.ulid_id === userId
          ) {
            return { ...dialog, new_badge_active: false };
          }
          return dialog;
        });
      }
    },

    removeDialogMessage(
      state,
      action: PayloadAction<{
        messageId: number;
        updatedDialog: ChatDialog;
      }>
    ) {
      const { messageId, updatedDialog } = action.payload;

      const contactId = updatedDialog.contact?.ulid_id;
      const userId = updatedDialog.user?.ulid_id;

      const isExistInDialogs = Boolean(
        state.dialogs.data.find(
          (dialogItem) =>
            dialogItem.contact?.ulid_id === contactId &&
            dialogItem.user?.ulid_id === userId
        )
      );

      const isExistInDialogMessages = state.messages[
        `${contactId}-${userId}`
      ]?.messages?.find((messageItem) => messageItem.id === messageId);

      if (isExistInDialogMessages) {
        state.messages[`${contactId}-${userId}`].messages = state.messages[
          `${contactId}-${userId}`
        ].messages.filter((messageItem) => messageItem.id !== messageId);
      }

      if (isExistInDialogs) {
        state.dialogs.data = state.dialogs.data.map((dialogItem) => {
          if (
            dialogItem.contact?.ulid_id === contactId &&
            dialogItem.user?.ulid_id === userId
          ) {
            return {
              ...updatedDialog,
              user: dialogItem.user || updatedDialog.user,
              contact: dialogItem.contact || updatedDialog.contact,
            };
          }

          return dialogItem;
        });
      }
    },
    // ? ***************** DIALOGS ACTIONS END *****************

    // ? ***************** MESSAGES ACTIONS *****************
    setMessages(
      state,
      action: PayloadAction<{
        isLiked: boolean;
        isEnabledEroticMedia: boolean;
        isBlocked: boolean;
        user: UserContact;
        contact: UserContact;
        next: string | null;
        limits: ChatLimits;
        dialog: ChatDialog;
        messages: ChatMessage[];
      }>
    ) {
      const { user, contact } = action.payload;

      state.messages[`${contact.ulid_id}-${user.ulid_id}`] = action.payload;
    },

    setMessagesLoading(state, action: PayloadAction<boolean>) {
      state.messagesLoading = action.payload;
    },

    addMessage(
      state,
      action: PayloadAction<{
        message: ChatMessage;
        contactId: string;
        userId: string;
      }>
    ) {
      const { message, contactId, userId } = action.payload;

      if (
        state.messages[`${contactId}-${userId}`] &&
        state.messages[`${contactId}-${userId}`].messages
      ) {
        let isMessageAlreadyExist = false;

        const newMessages = state.messages[
          `${contactId}-${userId}`
        ].messages.map((messageItem) => {
          if (
            (message.id && messageItem.id === message.id) ||
            (message.front_message_id &&
              messageItem.front_message_id === message.front_message_id)
          ) {
            isMessageAlreadyExist = true;

            return message;
          }

          return messageItem;
        });

        state.messages[`${contactId}-${userId}`].messages =
          isMessageAlreadyExist ? newMessages : [...newMessages, message];
      } else {
        state.messages[`${contactId}-${userId}`] = {
          ...state.messages[`${contactId}-${userId}`],
          messages: [message],
          next: null,
        };
      }
    },

    updateMessageStatus(
      state,
      action: PayloadAction<{
        message: Partial<ChatMessage>;
        contactId: string;
        userId: string;
      }>
    ) {
      const { message, contactId, userId } = action.payload;

      if (state.messages[`${contactId}-${userId}`]?.messages) {
        state.messages[`${contactId}-${userId}`].messages = state.messages[
          `${contactId}-${userId}`
        ].messages.map((oldMessage) => {
          return (message.id && oldMessage.id === message.id) ||
            (message.front_message_id &&
              oldMessage.front_message_id === message.front_message_id)
            ? { ...oldMessage, status: message.status || oldMessage.status }
            : oldMessage;
        });
      }
    },

    addMessages(
      state,
      action: PayloadAction<{
        messages: ChatMessage[];
        contactId: string;
        userId: string;
        next: string | null;
      }>
    ) {
      const { messages, contactId, userId, next } = action.payload;

      if (!state.messages[`${contactId}-${userId}`]?.messages) return;

      const allMessages =
        state.messages[`${contactId}-${userId}`].messages.concat(messages);

      state.messages[`${contactId}-${userId}`] = {
        ...state.messages[`${contactId}-${userId}`],
        messages: allMessages,
        next,
      };
    },

    markMessagesAsRead(
      state,
      action: PayloadAction<{ contactId: string; userId: string }>
    ) {
      const {
        payload: { contactId, userId },
      } = action;

      if (
        !state.messages[`${contactId}-${userId}`] ||
        !state.messages[`${contactId}-${userId}`].messages
      ) {
        return;
      }

      state.messages[`${contactId}-${userId}`].messages = state.messages[
        `${contactId}-${userId}`
      ].messages.map(setMessageRead());
    },

    markChatAsBlocked(
      state,
      action: PayloadAction<{ userId: string; contactId: string }>
    ) {
      const { userId, contactId } = action.payload;

      if (state.messages[`${contactId}-${userId}`])
        state.messages[`${contactId}-${userId}`].isBlocked = true;
    },
    // ? ***************** MESSAGES ACTIONS *****************

    // ? ***************** CONTACT ACTIONS *****************
    likeChatContact(
      state,
      action: PayloadAction<{
        contactId: string;
        userId: string;
        isLiked: boolean;
      }>
    ) {
      const { contactId, userId, isLiked } = action.payload;

      if (state.messages[`${contactId}-${userId}`])
        state.messages[`${contactId}-${userId}`].isLiked = isLiked;
    },
    // ? ***************** CONTACT ACTIONS *****************

    // ? ***************** GIFTS ACTIONS *****************
    setGifts(state, action: PayloadAction<ChatGift[]>) {
      state.gifts = action.payload;
    },
    setGiftsLoading(state, action: PayloadAction<boolean>) {
      state.giftsLoading = action.payload;
    },
    // ? ***************** GIFTS ACTIONS *****************

    // ? ***************** STICKERS ACTIONS *****************
    setStickerPacks(state, action: PayloadAction<ChatStickerPack[]>) {
      state.stickerPacks = action.payload;
    },
    setStickerPacksLoading(state, action: PayloadAction<boolean>) {
      state.stickerPacksLoading = action.payload;
    },
    // ? ***************** STICKERS ACTIONS *****************

    addTypingContact(state, action: PayloadAction<TypingContactInfo>) {
      const {
        payload: { contactId, userId },
      } = action;

      const alreadyTyping = state.typingContacts.find(
        (typingItem) =>
          typingItem.contactId === contactId && typingItem.userId === userId
      );

      if (!alreadyTyping)
        state.typingContacts = [{ contactId, userId }, ...state.typingContacts];
    },

    removeTypingContact(state, action: PayloadAction<TypingContactInfo>) {
      const {
        payload: { contactId, userId },
      } = action;

      state.typingContacts = state.typingContacts.filter(
        (typingItem) =>
          typingItem.contactId !== contactId || typingItem.userId !== userId
      );
    },

    resetState() {
      return initialState;
    },
  },
});

export const {
  updateChatContactLimits,

  setDialogsFilters,
  setDialogsCounters,
  setDialogs,
  setDialogsLoading,
  addDialogs,
  addDialog,
  removeDialog,
  updateDialogWithNewMessage,
  updateDialogReadStatus,
  updateDialogSaveStatus,
  hideDialog,
  unHideDialog,
  updateDialogLastMessageIncomingStatus,
  markDialogAsNotNew,
  removeDialogMessage,

  setMessages,
  addMessage,
  updateMessageStatus,
  addMessages,
  markMessagesAsRead,
  markChatAsBlocked,
  setMessagesLoading,

  likeChatContact,

  setGifts,
  setGiftsLoading,

  setStickerPacks,
  setStickerPacksLoading,

  addTypingContact,
  removeTypingContact,

  resetState,
} = messengerSlice.actions;

export const messenger = messengerSlice.reducer;
