import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setXUlidId } from 'api/httpClient';
import { useAppMeta } from 'hooks/useAppMeta';
import { useBrowserBadge } from 'hooks/useBrowserBadge';
import { useInitializeApp } from 'hooks/useInitializeApp';
import { useInterceptors } from 'hooks/useInterceptors';
import { useMirrorThemeColors } from 'hooks/useMirrorTheme';
import { useOnlinePing } from 'hooks/useOnlinePing';
import { useScrollRestoration } from 'hooks/useScrollRestoration';
import { useUserProfileUlid } from 'hooks/useUserProfileUlid';
import { useXPrevUrl } from 'hooks/useXPrevUrl';
import { useXRefererUrl } from 'hooks/useXRefererUrl';
import { getAuthenticated } from 'store/auth/selectors';
import { setPrevPagePathname } from 'store/common/commonSlice';

import { FullscreenSpinner } from 'components/shared/FullscreenSpinner';
import { SystemPopup } from 'components/shared/SystemPopup';

import { Routes } from './Routes';

export const App = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { isInitialized } = useInitializeApp();

  const isAuthenticated = useSelector(getAuthenticated);

  const { userProfileUlid } = useUserProfileUlid();

  useXPrevUrl();
  useXRefererUrl();
  useInterceptors();
  useOnlinePing(!!isAuthenticated);

  useAppMeta();
  useBrowserBadge();
  useMirrorThemeColors();
  useScrollRestoration();

  useEffect(() => {
    return () => {
      dispatch(setPrevPagePathname(pathname));
    };
  }, [dispatch, pathname]);

  useEffect(() => {
    if (userProfileUlid) setXUlidId(userProfileUlid);
  }, [userProfileUlid]);

  if (!isInitialized) {
    return <FullscreenSpinner />;
  }

  return (
    <>
      <SystemPopup />
      <Routes />
    </>
  );
};
