import { MailFormat } from 'types/enums/mails/MailFormat';
import { MailsTab } from 'types/enums/mails/MailsTab';
import { ChatLimits } from 'types/interfaces/chat/ChatLimits';
import { Mail } from 'types/interfaces/mails/Mail';
import { MailsChain } from 'types/interfaces/mails/MailsChain';
import { UserContact } from 'types/interfaces/user/UserContact';

import { httpClient } from './httpClient';

interface MailsChainResponse {
  data: MailsChain[];
  next: string | null;
}

interface MailsResponse {
  next: string | null;
  user: UserContact;
  contact: UserContact;
  inmails: Mail[];
  is_liked: boolean;
  blocked_me: boolean;
  spent: {
    over_30: boolean;
  };
  limits: ChatLimits;
}

interface SendTextMailsMessagePayload {
  contactId: string;
  body: string;
  photosIds: number[];
}

const readIncomeMails = (message: Mail) => {
  if (!message.is_incoming) {
    return message;
  }

  return {
    ...message,
    read_at: new Date().toUTCString(),
  };
};

export const MailsApi = {
  async fetchMailsChains(tab: MailsTab) {
    const { data } = await httpClient.get<MailsChainResponse>(
      `/inmails?tab=${tab}`,
      {
        headers: {
          'X-ULID': null,
        },
      }
    );

    return data;
  },

  async fetchMoreMailsChains(nextUrl: string) {
    const { data } = await httpClient.get<MailsChainResponse>(nextUrl, {
      headers: {
        'X-ULID': null,
      },
    });

    return data;
  },

  async fetchMails(contactId: string) {
    const { data } = await httpClient.get<MailsResponse>(
      `/inmails/${contactId}`
    );

    return {
      ...data,
      inmails: data.inmails?.map(readIncomeMails) || [],
    };
  },

  async fetchMoreMails(url: string) {
    const { data } = await httpClient.get<MailsResponse>(url);

    return data;
  },

  async sendTextMail(payload: SendTextMailsMessagePayload) {
    return httpClient.post<{ message: Mail }>('/inmails', {
      type: MailFormat.Text,
      body: payload.body,
      receiver_id: payload.contactId,
      photos: payload.photosIds,
    });
  },
};
