import React from 'react';
import { Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import { RoutesRestrictionType } from 'types/enums/RoutesRestrictionType';

import { useMedia } from 'hooks/useMedia';

import { AppRoute } from 'components/shared/AppRoute';

const EditProfilePage = loadable(
  () =>
    import(/* webpackChunkName: "EditProfilePage" */ 'pages/EditProfilePage'),
  {
    resolveComponent: (component) => component.EditProfilePage,
  }
);

const Faq = loadable(
  () =>
    import(/* webpackChunkName: "Faq" */ 'components/sections/MyProfile/Faq'),
  {
    resolveComponent: (component) => component.Faq,
  }
);

const SettingsMobile = loadable(
  () =>
    import(
      /* webpackChunkName: "SettingsMobile" */ 'components/sections/MyProfile/Settings/SettingsMobile'
    ),
  {
    resolveComponent: (component) => component.SettingsMobile,
  }
);

const ContactUs = loadable(
  () =>
    import(
      /* webpackChunkName: "ContactUs" */ 'components/sections/MyProfile/ContactUs'
    ),
  {
    resolveComponent: (component) => component.ContactUs,
  }
);

const NotFoundPage = loadable(
  () => import(/* webpackChunkName: "NotFoundPage" */ 'pages/NotFoundPage'),
  {
    resolveComponent: (component) => component.NotFoundPage,
  }
);

const ChatPage = loadable(
  () => import(/* webpackChunkName: "ChatPage" */ 'pages/ChatPage'),
  {
    resolveComponent: (component) => component.ChatPage,
  }
);

const IcebreakersPage = loadable(
  () =>
    import(/* webpackChunkName: "IcebreakersPage" */ 'pages/IcebreakersPage'),
  {
    resolveComponent: (component) => component.IcebreakersPage,
  }
);

const LegalPage = loadable(
  () => import(/* webpackChunkName: "LegalPage" */ 'pages/LegalPage'),
  {
    resolveComponent: (component) => component.LegalPage,
  }
);

const UserProfilePage = loadable(
  () =>
    import(/* webpackChunkName: "UserProfilePage" */ 'pages/UserProfilePage'),
  {
    resolveComponent: (component) => component.UserProfilePage,
  }
);

const FinderPage = loadable(
  () => import(/* webpackChunkName: "FinderPage" */ 'pages/FinderPage'),
  {
    resolveComponent: (component) => component.FinderPage,
  }
);

const MessengerPage = loadable(
  () => import(/* webpackChunkName: "MessengerPage" */ 'pages/MessengerPage'),
  {
    resolveComponent: (component) => component.MessengerPage,
  }
);

const MailsChainsPagePage = loadable(
  () =>
    import(/* webpackChunkName: "MailsChatsPage" */ 'pages/MailsChainsPage'),
  {
    resolveComponent: (component) => component.MailsChatsPage,
  }
);

const MailsChatPage = loadable(
  () => import(/* webpackChunkName: "MailsChatPage" */ 'pages/MailsChatPage'),
  {
    resolveComponent: (component) => component.MailsChatPage,
  }
);

const MyProfilePage = loadable(
  () => import(/* webpackChunkName: "MyProfilePage" */ 'pages/MyProfilePage'),
  {
    resolveComponent: (component) => component.MyProfilePage,
  }
);

const LoginPage = loadable(
  () => import(/* webpackChunkName: "LoginPage" */ 'pages/LoginPage'),
  {
    resolveComponent: (component) => component.LoginPage,
  }
);

const IndexPage = loadable(
  () => import(/* webpackChunkName: "IndexPage" */ 'pages/IndexPage'),
  {
    resolveComponent: (component) => component.IndexPage,
  }
);

export const Routes = () => {
  const { isMobile } = useMedia();

  return (
    <Switch>
      <AppRoute path="/login" component={LoginPage} isPublic exact />

      <AppRoute path="/terms" component={LegalPage} exact />
      <AppRoute path="/disclosures-disclaimers" component={LegalPage} exact />
      <AppRoute path="/compliance-statement" component={LegalPage} exact />
      <AppRoute
        path="/(privacy|tracking|scam)-policy"
        component={LegalPage}
        exact
      />

      <AppRoute path="/trusted-policy" component={LegalPage} exact isPrivate />

      <AppRoute path="/" component={IndexPage} isPublic exact />
      <AppRoute path="/finder" component={FinderPage} isPrivate exact />

      <AppRoute path="/dialogs" component={MessengerPage} isPrivate exact />
      <AppRoute
        path="/dialogs/:contactId"
        component={isMobile ? ChatPage : MessengerPage}
        isPrivate
      />

      <AppRoute path="/mails" component={MailsChainsPagePage} isPrivate exact />
      <AppRoute path="/mails/:contactId" component={MailsChatPage} isPrivate />

      <AppRoute path="/my-profile" component={MyProfilePage} isPrivate exact />
      <AppRoute
        path="/my-profile/contact-us"
        component={ContactUs}
        isPrivate
        exact
      />
      <AppRoute path="/my-profile/faq" component={Faq} isPrivate exact />

      <AppRoute
        path="/my-profile/edit"
        component={EditProfilePage}
        isPrivate
        exact
      />
      <AppRoute
        path="/my-profile/settings"
        component={SettingsMobile}
        isPrivate
        exact
      />

      <AppRoute path="/dialogs" component={MessengerPage} isPrivate exact />
      <AppRoute
        path="/dialogs/:contactId"
        component={isMobile ? ChatPage : MessengerPage}
        isPrivate
      />

      <AppRoute
        path="/icebreakers"
        component={IcebreakersPage}
        restrictedType={RoutesRestrictionType.MobileRestricted}
        isPrivate
      />

      <AppRoute
        path="/user/:userId"
        component={UserProfilePage}
        isPrivate
        exact
      />

      <AppRoute path="*" component={NotFoundPage} />
    </Switch>
  );
};
