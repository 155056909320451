import React, { memo, ReactElement, useMemo } from 'react';
import cx from 'classnames';

import { UserGender } from 'types/enums/UserGender';

import { getPhotoPlaceholders } from 'helpers/photoPlaceholders';
import { getIsEnabledUserAvatar } from 'helpers/profiles';

import css from './userAvatar.module.sass';

interface Props {
  url?: string | null;
  userUrl?: string | null;
  gender?: UserGender;
  isOnline?: boolean;
  size?: number;
  className?: string;
  shouldShowUser?: boolean;
  shouldShowOnlineBadge?: boolean;
  badge?: ReactElement;
}

export const UserAvatar: React.FC<Props> = memo(
  ({
    url,
    userUrl,
    shouldShowUser,
    gender,
    isOnline = false,
    className = '',
    size,
    shouldShowOnlineBadge = false,
    badge,
  }) => {
    const safeContactUrl = useMemo(
      () => url || getPhotoPlaceholders(gender, 1)[0],
      [gender, url]
    );

    const safeUserUrl = useMemo(
      () => userUrl || getPhotoPlaceholders(gender, 1)[0],
      [gender, userUrl]
    );

    const isEnabledUserAvatar = useMemo(
      () => shouldShowUser && getIsEnabledUserAvatar(),
      [shouldShowUser]
    );

    return (
      <div className={css.root}>
        {isEnabledUserAvatar && (
          <div
            className={cx(css.userAvatar)}
            style={{
              width: `${size || 100}px`,
              minWidth: `${size || 100}px`,
              height: `${size || 100}px`,
              backgroundImage: `url(${safeUserUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        )}
        <div
          className={cx(css.contactAvatar, className, {
            [css.contactWithUser]: isEnabledUserAvatar,
          })}
          style={{
            width: `${size || 100}px`,
            minWidth: `${size || 100}px`,
            height: `${size || 100}px`,
            backgroundImage: `url(${safeContactUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            marginLeft: isEnabledUserAvatar ? `${(size || 0) * 0.5}px` : '0',
          }}
        >
          {!!badge && <div className={css.badge}>{badge}</div>}

          {shouldShowOnlineBadge && !badge && (
            <p className={cx(css.offline, { [css.online]: isOnline })} />
          )}
        </div>
      </div>
    );
  }
);
