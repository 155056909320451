import cookie from 'js-cookie';
import { AppThunk } from 'store';

import { clearPopupStorageQueue } from 'contexts/PopupContext';

import { AuthApi } from 'api/AuthApi';
import { resetState as resetCommonState } from 'store/common/commonSlice';
import { resetState as resetFinderState } from 'store/finder/finderSlice';
import { resetState as resetIcebreakersState } from 'store/icebreakers/icebreakersSlice';
import { resetState as resetMailsState } from 'store/mails/mailsSlice';
import { resetState as resetMessengerState } from 'store/messenger/messengerSlice';
import { resetState as resetNotificationsState } from 'store/notifications/notificationsSlice';
import { resetState as resetProfileState } from 'store/profile/profileSlice';
import { resetState as resetSystemPopupState } from 'store/systemPopup/systemPopupSlice';

import {
  authFailed,
  authStart,
  authSuccess,
  setAuthenticated,
  setUser,
} from './authSlice';

export const login =
  (payload: {
    email: string;
    password: string;
    auth_code?: string;
  }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(authStart());

      const user = await AuthApi.login(payload);

      dispatch(authSuccess(user));
      dispatch(setAuthenticated({ userId: user.ulid_id }));
    } catch (err) {
      dispatch(authFailed());

      throw err;
    }
  };

export const logout = (): AppThunk => async (dispatch) => {
  clearPopupStorageQueue();

  try {
    cookie.remove('tru_userid', {
      domain: window.location.hostname.split('.').slice(1).join('.'),
    });

    await AuthApi.logout();
  } finally {
    dispatch(setUser(null));
    dispatch(setAuthenticated({ userId: null }));

    dispatch(resetCommonState());
    dispatch(resetFinderState());
    dispatch(resetIcebreakersState());
    dispatch(resetMailsState());
    dispatch(resetMessengerState());
    dispatch(resetNotificationsState());
    dispatch(resetProfileState());
    dispatch(resetSystemPopupState());
  }
};
