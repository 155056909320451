import React from 'react';

import { UserAvatar } from 'components/shared/UserAvatar';

import css from './notificationToast.module.sass';

export interface NotificationToastProps {
  photo: string;
  name: string;
  message: string;
  onClick?: () => void;
}

export const NotificationToast: React.FC<NotificationToastProps> = ({
  photo,
  name,
  message,
  onClick,
}) => {
  return (
    <div className={css.root} onClick={onClick}>
      <UserAvatar url={photo} size={50} />

      <div className={css.content}>
        <p className={css.title}>{name}</p>
        <p className={css.subtitle}>{message}</p>
      </div>
    </div>
  );
};
