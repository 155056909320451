import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { LoadableComponent } from '@loadable/component';

import { RoutesRestrictionType } from 'types/enums/RoutesRestrictionType';

import { notifyRouteLoadEvents } from 'helpers/routeEvents';
import { useMedia } from 'hooks/useMedia';
import { getAuthenticated } from 'store/auth/selectors';
import { init } from 'store/common/thunks';

import { FullscreenSpinner } from '../FullscreenSpinner';

interface IProps<T> extends RouteProps {
  component: React.ComponentType<T> | LoadableComponent<T>;
  componentProps?: T;
  isPublic?: boolean;
  isPrivate?: boolean;
  restrictedType?: RoutesRestrictionType;
}

export const AppRoute = <T extends object>({
  component: Component,
  componentProps,
  isPrivate, // ? only for authorized users
  isPublic, // ? only for non authorized users
  restrictedType,
  ...routerProps
}: IProps<T>) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { isMobile, isTablet, isDesktop } = useMedia();

  const isAuthenticated = useSelector(getAuthenticated);

  useEffect(() => {
    notifyRouteLoadEvents(Component, pathname);
  }, [Component, pathname]);

  useEffect(() => {
    if (isAuthenticated && isPrivate) {
      dispatch(init());
    }
  }, [dispatch, isAuthenticated, isPrivate]);

  if (isPrivate && !isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (isPublic && isAuthenticated) {
    return <Redirect to="/finder" />;
  }

  if (
    restrictedType &&
    restrictedType === RoutesRestrictionType.MobileRestricted &&
    isMobile
  ) {
    return <Redirect to="/finder" />;
  }

  if (
    restrictedType &&
    restrictedType === RoutesRestrictionType.DesktopRestricted &&
    (isDesktop || isTablet)
  ) {
    return <Redirect to="/finder" />;
  }

  return (
    <Route
      {...routerProps}
      render={() => {
        return (
          // @ts-ignore
          <Component {...componentProps} fallback={<FullscreenSpinner />} />
        );
      }}
    />
  );
};
