import { getSessionStorageItem } from './sessionStorage';

interface Profile {
  ulid: string;
  name: string;
  email: string;
  external_id: string;
  image_url: string;
}

export const getProfilesListInfo = (): Profile[] | null => {
  try {
    return JSON.parse(getSessionStorageItem('profilesInfo') || '');
  } catch (error) {
    return null;
  }
};

export const getIsEnabledUserAvatar = () =>
  getSessionStorageItem('isEnabledUserAvatar');
