import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { assertString } from 'helpers/utils/checkType';

export enum QueryKeys {
  LoginToken = 'login_token',
  AutoRegister = 'ar',
  InitialId = 'initial_id',
  DestinationPath = 'dp',
  UserId = 'user_id',
  ClickId = 'click_id',
  MailsFiltersTab = 'mails_tab',
  IcebreakerTab = 'tab',
  XUlid = 'x_ulid',
}

export const useSystemSearchQueries = () => {
  const { search } = useLocation();

  const {
    [QueryKeys.LoginToken]: loginToken,
    [QueryKeys.AutoRegister]: autoRegister,
    [QueryKeys.InitialId]: initialId,
    [QueryKeys.DestinationPath]: destinationPath,
    [QueryKeys.UserId]: userId,
    [QueryKeys.ClickId]: clickId,
    [QueryKeys.MailsFiltersTab]: mailsFiltersTab,
    [QueryKeys.IcebreakerTab]: icebreakerTab,
    [QueryKeys.XUlid]: xUlid,
  } = queryString.parse(search);

  const autoLoginQuery = useMemo(() => {
    return {
      key: QueryKeys.LoginToken,
      value: assertString(loginToken) ? loginToken : null,
    };
  }, [loginToken]);

  const autoRegisterQuery = useMemo(() => {
    return {
      key: QueryKeys.AutoRegister,
      value: assertString(autoRegister) ? autoRegister : null,
    };
  }, [autoRegister]);

  const initialIdQuery = useMemo(() => {
    return {
      key: QueryKeys.InitialId,
      value: assertString(initialId) ? initialId : null,
    };
  }, [initialId]);

  const destinationPathQuery = useMemo(() => {
    return {
      key: QueryKeys.DestinationPath,
      value: assertString(destinationPath) ? destinationPath : null,
    };
  }, [destinationPath]);

  const userIdQuery = useMemo(() => {
    return {
      key: QueryKeys.UserId,
      value: assertString(userId) ? userId : null,
    };
  }, [userId]);

  const clickIdQuery = useMemo(() => {
    return {
      key: QueryKeys.ClickId,
      value: assertString(clickId) ? clickId : null,
    };
  }, [clickId]);

  const mailsFiltersTabQuery = useMemo(() => {
    return {
      key: QueryKeys.MailsFiltersTab,
      value: assertString(mailsFiltersTab) ? mailsFiltersTab : null,
    };
  }, [mailsFiltersTab]);

  const icebreakerTabQuery = useMemo(() => {
    return {
      key: QueryKeys.IcebreakerTab,
      value: assertString(icebreakerTab) ? icebreakerTab : null,
    };
  }, [icebreakerTab]);

  const xUlidQuery = useMemo(() => {
    return {
      key: QueryKeys.XUlid,
      value: assertString(xUlid) ? xUlid : null,
    };
  }, [xUlid]);

  return {
    autoLoginQuery,
    autoRegisterQuery,
    initialIdQuery,
    destinationPathQuery,
    userIdQuery,
    clickIdQuery,
    mailsFiltersTabQuery,
    icebreakerTabQuery,
    xUlidQuery,
  };
};
