import { useEffect, useState } from 'react';

import { TrackingApi } from 'api/TrackingApi';
import { useI18n } from 'hooks/useI18n';
import { useInitialId } from 'hooks/useInitialId';

export const useInitializeApp = () => {
  const { getInitialId, setInitialIdToCookie } = useInitialId();
  const { initI18n } = useI18n();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      try {
        const initialId = await getInitialId();

        setInitialIdToCookie(initialId);
      } catch (err: any) {
        TrackingApi.trackError({
          message: `set initial_id fail: ${err?.message}`,
        });
      }

      await initI18n();

      setIsInitialized(true);
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isInitialized,
  };
};
